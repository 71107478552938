import React, { useEffect } from "react";

import { AppRoutes } from "../routes";
import "../assets/scss/themes.scss"; // Importing SCSS
import { Languages } from "../constants/language.constant";
import i18n from "../i18n";

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    configureLanguage(localStorage.getItem("i18nextLng") || "en");
  }, []);

  const configureLanguage = (locale: string) => {
    const html = window.document.documentElement;
    const language = Languages[locale];
    html.lang = locale;
    html.dir = language.writingDirection;
    i18n.changeLanguage(locale);
  };
  return (
    <React.Fragment>
      <AppRoutes />
    </React.Fragment>
  );
};
